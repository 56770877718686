.filter_box{
    width: 110rem;
    height: 0;
    margin: 0 auto;
    margin-top: 0;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-template-rows: 1fr;
    gap: 3rem;
    border-bottom: 1px solid #d5d5d569;
    opacity: 0;
    position: relative;
    overflow: hidden;
    transform: scaleY(0);
    transform-origin: 0 0 0;
  

    transition: all .2s ease-in-out;
}
.btn_div_filter{
    width: 110rem;
    /*background: red;*/
    margin: 7rem auto 2rem;
    display: flex;
    justify-content: end;
}

.filter_box_show{
    opacity: 1;
    height: 120px;
    margin-top: 5rem;
    transform: scaleY(1);
}

.btnFilter i{
    font-size: 20px;
    transform: rotate(0);
    pointer-events: none;
    transition: all .5s ease;

}

.btn_box_show{
    transform: scale(1);
    transition: filter 0.3s ease 0.3s;
}

.btn_box_show:active{
    transform: scale(0.9);
    transition: filter 0s;
}




.btn_box_show i{
  transform: rotate(180deg);
}


.between-span{
    width: 10px;
    height: 2px;
    background: #c4c4c4;
    border-radius: 1px;
}




.btnFilter {
	height: 36px;
	font-size: 16px;
	padding: 0 20px;
	display: flex;
	gap: 10px;
	text-transform: capitalize;
	align-items: center;
	cursor: pointer;
    color: #000;
    border-radius: 16px;
    background: #e2e2e4;
    border: none;
}



label.MuiFormLabel-root{
    font-size: 14px;
    /* margin-bottom: 10px; */
}


.input_filter{
    height: 40px !important;
}

.css-699ro3-MuiInputBase-root-MuiOutlinedInput-root {
	height: 40px;
}

.select-text{
    font-size: 14px;
}

.el_box{
    
    display: flex;
    flex-direction: column;
    gap: 10px;
}

@media screen and (max-width:1200px){

    .filter_box{
        width: 100%;
        height: 0;
        margin: 0 auto;
        margin-top: 5rem;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 260px;
        grid-template-rows: repeat(3,65px);
        gap: 3rem;
    }

    .filter_box_show{
        opacity: 1;
        height: 120px;
        margin-top: 5rem;
        height: 110px;
    }

    .btn_div_filter{
        width: 100%;
    }

}

@media screen and (max-width:768px) {

    .filter_box{
        width: 100%;
        height: 0;
        margin: 0 auto;
        margin-top: 5rem;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3,55px);
        gap: 3rem;
    }

    .filter_box_show{
        opacity: 1;
        height: 120px;
        margin-top: 5rem;
        height: 400px;
    }

    .btn_div_filter{
        width: 100%;
    }
    .filter_box span.MuiTypography-root{
        font-size: 13px;
    }
    
    label.MuiFormLabel-root{
        font-size: 14px;
    }
    
}