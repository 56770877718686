.ModelForm {
    width: 100%;
    height: 100vh;
    /*background: rgba(0, 0, 0, 0.9);*/
    z-index: 9999999;
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    /* From https://css.glass */
    background: rgba(53, 53, 53, 0.4);
    backdrop-filter: blur(13px);
    -webkit-backdrop-filter: blur(13px);
    transition: all 0.3s ease;
    animation: test .5s ease;
}

@keyframes test {

  0%{
    opacity: 0;
  }
  100%{
    opacity: 100;
  }
  
}

.formDiv {
    width: 92%;
    max-width: 400px;
    height: auto;
    background: rgb(255, 255, 255);
    padding: 0 0 60px;
    box-shadow: 5px 5px 5px #0000002b;
    max-height: 80vh;
    overflow-y: scroll;
}
.formDiv h2 {
    text-align: center;
    line-height: 90px;
    font-size: 20px;
}
.form_box {
    background: #fff;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
}
.form_box label {
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 6px;
    color: #757575;
}
.form_box label i {
    font-size: 16px;
    color: #d0a149;
}
.form_box span {
    color: rgb(255, 88, 88);
    font-size: 12px;
}
.ModelForm .box-form__car-time,
.ModelForm .box-form__car-type {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 0 30px;
    gap: 8px;
}
.form_box input,
.form_box select {
    width: 100%;
    height: 38px;
    margin: 0 auto;
    border-radius: 4px;
    border: 1px solid #acacac;
    padding: 0 10px;
    background: transparent;
}

select {
    appearance: none;
}

.div_input {
    position: relative;
}

.div_input > i {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    color: #d0a149;
    transition: all 0.3s ease;
}
.div_input:hover i {
    transform: translateY(-50%) rotate(180deg);
}
.div_input > select {
    width: 100% !important;
}

.form_box input:is(:active, :focus) {
    outline: none;
    box-shadow: none;
}

.book-content__box .box-form__car-time input,
.book-content__box .box-form__car-type select,
.book-content__box button.btn_recherche_ {
    border-radius: 6px;
}

.hero-content__text__btns a,
.navbar__buttons a {
    height: 50px;
}

.btn_submit input {
    background: #bb9041;
    background: #d0a149;
    cursor: pointer;
    border: none;
    color: #fff;
    font-size: 16px;
    margin-top: 20px;
}

.models-div__box__descr__name-price__btn {
    padding: 0;
}
.models-div__box__descr__name-price__btn button {
    width: 100%;
    height: 44px;
    cursor: pointer;
    border: none;
    background: transparent;
    color: #ffffff;
    font-size: 16px;
}

span.loading {
    width: 100%;
    /*background: red;*/
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #444;
    grid-column: 1/ 3 span;
}

.models-div__box__img {
    position: relative;
}

.models-div__box__img span.city {
    background: #d0a149;
    position: absolute;
    top: 10px;
    z-index: 10;
    padding: 6px 20px;
    font-size: 16px;
    right: 10px;
    color: #fff;
    user-select: none;
    border-radius: 4px;
}

.pick-description__price {
    border: 2px solid #706f7b;
    font-size: 1.2rem;
}

.box-form__car-time > div {
    width: 100%;
}

.error_input {
    border: 1px solid rgb(255, 37, 37) !important;
}

@media screen and (min-width: 1200px) {
    .formDiv {
        max-height: 90vh;
        max-width: 660px;
        overflow-y: scroll;
        padding: 40px 50px 60px;
    }
    .form_box {
        grid-gap: 10px;
        background: #fff;
        display: grid;
        gap: 15px 20px;
        grid-template-columns: 1fr 1fr;
    }

    .ModelForm .box-form__car-time,
    .ModelForm .box-form__car-type {
        padding: 0;
        gap: 8px;
    }

    .date_picker_div {
        grid-column: 1/2 span;
    }
    .btn_submit {
        grid-column: 2/1 span;
    }
}

.banner-section > .container {
    display: flex;
    gap: 50px;
    position: relative;
}
.banner-content__text {
    text-align: left;
    width: calc(100% - 360px);
}
.banner-section > .container > .icone_location {
    width: 310px;
    height: 310px;
    background: transparent;
    position: absolute;
    bottom: -40px;
    right: 0;
}
.banner-section {
    background-color: #d0a24a;
    padding: 5rem 0;
}
.banner-content__text p {
    font-size: 2.2rem;
    color: #674c1b;
}

.about-main__text__icons__box img {
    filter: grayscale(100%);
}

.plan-container__title h2 {
    font-size: 2.6rem;
    font-family: 'Poppins', sans-serif;
    margin: 1.3rem 0 3rem 0;
}

.about-main__text__icons__box span h4 {
    font-size: 1.8rem;
}

.about-main__text__icons__box {
    gap: 15px;
}
.contact-div__text h2 {
    font-size: 3.5rem;
    line-height: 1.2;
    margin-bottom: 2rem;
}
.about-main__text__icons__box i {
    font-size: 50px;
    color: #d0a149;
}

.pick-description .cta-btn {
    font-size: 1.8rem;
}

/* loading */

.loading_div {
    width: 100%;
    height: 100vh;
    background: #f8f8f8;
    z-index: 999999999999;
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: all 0.3s ease;
    animation: loadingx 0.2s ease;
}

@keyframes loadingx {
}

.remove_loading {
    opacity: 0 !important;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    transform: scale(0.7);
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #d0a149;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #d0a149 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* loading */

/* div filter */

.models-div {
    position: relative;
}

.div_filter {
    position: absolute;
    top: 30px;
    right: -10px;
    /*background: red;*/
}

.slider_box {
    background: #f7f7f77c;
    height: 100px;
    min-width: 150px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider_box a {
    display: flex;
    align-items: center;
    justify-content: center;
}

.splide__pagination,
.splide__arrow {
    display: none !important;
}

.pick-box button {
    height: 44px;
    padding: 0 2.5rem;
    font-weight: 400;
    font-size: 1.7rem;
}
.pick-description__table__col {
    height: 44px;
}
.pick-description__price {
    font-size: 1.7rem;
    padding: 0.3rem 1.9rem 0.3rem 4rem;
    white-space: nowrap;
    height: 44px;
    font-weight: 400;
}
.pick-description__price span {
    font-size: 2.2rem;
    font-weight: 500;
}
.pick-description .cta-btn {
    font-size: 1.7rem;
    height: 44px;
    font-weight: 500;
    box-shadow: none;
}
.pick-description__table__col {
    border: none !important;
    background: #e9e9e9;
}
.pick-description__table__col:nth-child(2n + 1) {
    background-color: #dbdbdb;
}
.pick-description__price {
    border: none !important;
    margin-bottom: 15px;
}

.lineForm {
    grid-column: 1/3;
    height: 2px;
    /*background: #cacaca;*/
    margin: 20px 0 20px;
    position: relative;
    border-bottom: 2px dashed #acacac73;
}

.lineForm_2 {
    margin: 10px 0 0px;
}

.lineForm span {
    position: absolute;
    inset: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 40px;
    background: #fff;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #757575;
    text-transform: uppercase;
}

.box-form__car-time_two_input {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
    grid-template-rows: 1fr;
    gap: 20px !important;
}

.box-form__car-time_two_input input {
    user-select: none !important;
}

.box-form__car-time_two_input .boxx {
    position: relative;
}

.box-form__car-time_two_input .boxx .dhSpan {
    position: absolute;
    width: 38px;
    height: 38px;
    background: rgba(105, 105, 105, 0.2);
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #757575;
    border-radius: 0 4px 4px 0;
    font-size: 12px;
    text-transform: uppercase;
    border-left: 1px solid #acacac;
}

.box-form__car-time_two_input .boxx::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.box-form__car-time_two_input .boxx {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 63px;
}

.form_box label {
    text-transform: capitalize;
}

.el_total {
    /*border: none !important;*/
    background: #f5f5f5 !important;
    font-size: 14px;
    font-weight: 500;
}

@media (max-width: 768px) {
    .slider_box {
        height: 100px;
        min-width: 100px;
    }
    .book-content__box {
        padding: 40px 20px;
    }
    .hero-content__car-img {
        display: block;
        width: 90%;
        position: static;
        margin: 0;
        grid-row: 1/2;
        margin-left: 5%;
    }
    .lineForm {
        grid-column: 1/2;
        width: 100%;
        margin: 30px auto 30px;
    }
    .hero-content {
        display: grid;
        /*background: red;*/
        grid-template-rows: 200px 1fr;
        transform: translateY(50px);
        height: auto;
        padding-top: 70px;
        padding-bottom: 40px;
    }
    .hero-section {
        height: auto;
        padding-bottom: 50px;
    }
    .hero-content__text h1 {
        font-size: 3.2rem;
        font-family: 'Poppins', sans-serif;
        padding: 20px 0px;
    }
    .hero-content__text {
        margin-top: 1rem;
    }
    .all-testimonials__box {
        width: auto;
    }
    .all-testimonials {
        flex-direction: column;
    }
    .banner-content__text {
        text-align: left;
        width: 100%;
    }
    .banner-section > .container {
        display: flex;
        gap: 10px;
        flex-direction: column-reverse;
        position: static;
    }
    .banner-section > .container > .icone_location {
        width: 100%;
        max-width: 260px;
        aspect-ratio: 1;
        /*background: red;*/
        bottom: -10px;
        right: 0;
        position: static;
        margin: 0 auto;
        display: none;
    }
    .banner-section {
        background-color: #d0a24a;
        padding: 6rem 2rem;
        margin: 20px auto;
    }
    .banner-content__text h2 {
        font-size: 2.9rem;
    }
    .pick-description {
        width: 100%;
        max-width: 400px;
    }
    .about-main__text__icons {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 4rem;
    }

    .contact-div {
        padding: 10rem 0 !important;
    }

    .book-section {
        margin-top: 80px;
    }
}

@media (min-width: 800px) {
    .models-div__box {
        min-height: 580px;
    }
    .models-div__box__descr__name-price__details {
        border-bottom: 1px solid #d1d1d1;
        height: 118px;
        flex: 1 1 auto;
    }
    .models-div__box__descr__name-price__name p,
    .models-div__box__descr__name-price__price h4 {
        font-size: 1.6rem !important;
        font-weight: 600;
        padding-bottom: 5px;
    }
}

.models-div__box__img img {
    width: 100%;
    aspect-ratio: 3/2 !important;
    object-fit: contain;
}

.all-testimonials__box {
    display: flex;
    flex-direction: column;
}
.all-testimonials__box p {
    flex: 1 1 auto;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .lineForm {
        grid-column: 1/2;
        width: 100%;
        margin: 30px auto 30px;
    }
}
