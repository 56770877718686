.inputs_register {
    /*background-color: red;*/
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}
.input_2 {
    grid-column: 1/3;
}

.inputs_register  input{
    box-shadow: none !important;
    outline: none !important;
}
.inputs_register  input:focus{
    box-shadow: none !important;
    outline: none !important;
    border: 1px solid rgba(0, 0, 0, 0.4);
}



.inputs input {
    height: 40px;
}

.inputs input:last-child {
    grid-column: 1/3;
}
.boxs,
.boxLogin {
    height: 610px;
}

.inputError {
    border-color: red !important;
}

.showPassword input {
    width: 100%;
    padding: 0 20px;
}

.boxs_register {
    max-width: 540px;
}
.boxs_register .boxLogin {
    max-width: 540px;
}

.showPassword {
    position: relative;
}
.showPassword input {
    background: transparent;
}
.showPassword button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    cursor: pointer;
}
.showPassword button i {
    font-size: 18px;
    color: #cccccc;
}

.phone_box {
    grid-column: 1/3;
    display: flex;
    gap: 20px;
}
.code_country {
    width: 100px;
    height: 40px;
    /*background: red;*/
}
.code_country *:active {
    box-shadow: none;
    outline: none;
}
.phone_box input {
    flex: 1;
}
.code_country .flag_country {
    display: flex !important;
    align-items: center;
    justify-content: start;
    gap: 10px;
    height: 33px;
    width: 100%;
    background: transparent;
    transform: translateY(0);
}
.flag_country {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
}

.boxSms{
    width: 360px;
    height: 50px;
    /*background: red;*/
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}
.boxSms input{
    width: 50px;
    height: 50px;
    background: transparent;
    border: 2px solid #acacac;
    border-radius: 12px;
   text-align: center;

}
.boxSms input:focus{
    outline: none;
    border: 2px solid #d0a149
}

.btnSms{
    border: none;
    height: 44px;
    width: 140px;
    font-size: 16px;
    cursor: pointer;
    background: #d0a149;
    color: #fff;
    border-radius: 6px;
}

.txtreg{
    font-size: 14px;
    text-align: center;
}

.btnBack{
    position: absolute;
    top: 30px;
    left: 30px;
    width: 36px;
    height: 36px;
    border: none;
    background: rgb(238, 238, 238);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.btnBack i{
    font-size: 20px;
    color: #646464;
}

.boxLogin{
    opacity: 1;
    animation: boxlogin 1s ease;
}

@keyframes boxlogin {

    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
    
}

@media only screen and (max-width: 600px) {
    .boxs,
    .boxLogin {
        height: 92vh;
        overflow-y: scroll;
    }
    .boxLogin{
        /*background: red;*/
        padding-bottom: 40px;
        padding-top: 40px;
    }

    .inputs_register {
        height: auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }

    .inputs_register input,
    .inputs_register .showPassword {
        grid-column: 1/3 !important;
    }

    .inputs input:last-child {
        grid-column: 1/2;
        margin-top: 20px;
    }
    .inputs input:last-child {
        grid-column: 1/2;
        margin-top: 0;
    }

    .boxSms{
        width: 300px;
    }
    .boxSms input {
        width: 44px;
        height: 44px;
    }
}
