.contianer_login {
  width: 100%;
  height: 100vh;
  position: relative;
  background-size: cover;
}

.contianer_login::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  /* background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px); */
  background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.651) 14%, rgba(0,212,255,0) 100%);
}

.boxs {
  width: 90vw;
  max-width: 490px;
  height: 560px;
  display: grid;
  grid-template-columns: 1fr;
  position: absolute;
  inset: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  overflow: hidden;
}

.boxLogin {
  width: 90vw;
  max-width: 490px;
  height: 560px;
  background: #ffffffbe;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.98);
  border-radius: 16px;
  backdrop-filter: blur(10.2px);
  -webkit-backdrop-filter: blur(10.2px);
  gap: 20px;
}

.logo {
  width: 180px;
  height: 60px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo img {
  width: 100%;
}
.contianer_login h2 {
  color: #010103;
  font-size: 20px;
}
.inputs {
  width: 90%;
  height: 190px;
  background: transparent;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.inputs input {
  height: 44px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.inputs input:not(:last-child) {
  padding: 0 20px;
  background: transparent;
}

.inputs input[type="button"] {
  background: #d0a149;
  border: none;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}

.inputs input:is(:focus, :active) {
  border: 2px solid #d0a149;
  outline: none;
}

.boxLogin a {
  font-size: 14px;
  text-decoration: none;
  transform: translateY(-10px);
  padding: 12px 22px;
	color: #ffffff;
	border-radius: 34px;
	background: #010103;
}



.bigLogo {
  width: 250px;
  height: 100px;
  background: rgb(231, 231, 231);
  position: absolute;
  top: 50%;
  left: 45rem;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 16px;
  backdrop-filter: blur(10.2px);
  -webkit-backdrop-filter: blur(10.2px);
}

.bigLogo img {
  width: 100%;
}

.contianer_login span {
  width: 85%;
  height: 1px;
  background: #cecece;
  transform: translateY(-15px);
}
